import { CustomerInfoCard } from '@components/Card/CustomerInfoCard'
import TripStatusCard from '@components/Card/TripStatusCard'
import TripSummaryCard from '@components/Card/TripSummaryCard'
import { Column, Row } from '@components/Grid'

const TripView = () => {
  return (
    <Row width="100%" gap="md">
      <Column width="70%" gap="lg">
        <Row width="100%" gap="sm">
          <CustomerInfoCard
            title="Driver Details"
            customerImage="https://images.pexels.com/photos/573238/pexels-photo-573238.jpeg?cs=srgb&dl=pexels-bertellifotografia-573238.jpg&fm=jpg"
            email="driver@gmail.com"
            customerName="Amanada Hart"
            phoneNumber="+977 9890909987"
          />
          <CustomerInfoCard
            title="Customer Details"
            customerImage="https://images.pexels.com/photos/27103860/pexels-photo-27103860/free-photo-of-a-young-woman-sitting-on-the-ground-with-green-plants-in-a-forest.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load"
            email="bradely@gmail.com"
            customerName="Bradely Cooper"
            phoneNumber="+977 9890909987"
          />
        </Row>
        <TripSummaryCard />
      </Column>
      <Row width="30%">
        <TripStatusCard />
      </Row>
    </Row>
  )
}

export default TripView
