import { doughnout, filterHorizontal } from '@assets/svg'
import { Column, Row } from '@components/Grid'
import MapComponent from '@components/MapComponent'
import SvgIcon from '@components/SvgIcon'
import Typography from '@components/Typography'

const TripStatusCard = () => {
  return (
    <Column background="white" padding="sm" width="100%" gap="md" radius="sm">
      <Typography align="center" fontSize="lg" fontWeight="xlg">
        Trip Status
      </Typography>
      <Column width="100%" gap="xs">
        <Typography fontColor="grey400">Trip Status</Typography>
        <Row width="100%" border="grey50" padding="sm" radius="sm">
          <Typography fontColor="grey200">Completed</Typography>
        </Row>
      </Column>
      <Column width="100%" gap="xs">
        <Typography fontColor="grey400">Payment Status</Typography>
        <Row width="100%" border="grey50" padding="sm" radius="sm">
          <Typography fontColor="grey200">Paid</Typography>
        </Row>
      </Column>
      <MapComponent />
      <Column gap="sm">
        <Row gap="sm" align="center">
          <SvgIcon src={doughnout} svgColor="primary" />
          <Typography>Bafa Dha Marg (Gwarko)</Typography>
        </Row>
        <Row gap="sm" align="center">
          <SvgIcon src={doughnout} svgColor="green500" />
          <Typography>Godawari Resort View Point (Jharuwarasi)</Typography>
        </Row>
        <Row gap="sm" align="center">
          <SvgIcon src={filterHorizontal} svgColor="skyBlue" size={16} />
          <Typography>Total Distance: 1.72 Km</Typography>
        </Row>
      </Column>
    </Column>
  )
}

export default TripStatusCard
