import { Column } from '@components/Grid'
import Dashcard, { dashcardData } from '@components/Dashcard'
import HighlightedLastWord from '@components/HighlightedLastWord'
import { dummyCustomers } from '@assets/dummyData/customerDetails'
import { CustomerInfoCard } from '@components/Card/CustomerInfoCard'
import { DashboardTable } from './dashboardTable'

// import DataTable from '@components/DataTable'
const Dashboard = () => {
  // const columns = [
  //   {
  //     title: 'Product Name',
  //     key: 'caption',
  //     render: (values: any) => {
  //       const caption = values?.row?.caption ?? 'N/A'
  //       const truncatedName =
  //         caption?.length > 20 ? caption?.slice(0, 40) + '...' : caption
  //       return (
  //         <Row gap="sm" align="center">
  //           <Typography variant="p" fontSize="md" fontWeight="lg">
  //             {truncatedName}
  //           </Typography>
  //         </Row>
  //       )
  //     },
  //   },
  //   {
  //     title: 'Jk Name',
  //     key: 'jkName',
  //     render: (values: any) => {
  //       const caption = values?.row?.jkName ?? 'N/A'
  //       const truncatedName =
  //         caption?.length > 20 ? caption?.slice(0, 40) + '...' : caption
  //       return (
  //         <Row gap="sm" align="center">
  //           <Typography variant="p" fontSize="md" fontWeight="lg">
  //             {truncatedName}
  //           </Typography>
  //         </Row>
  //       )
  //     },
  //   },
  //   {
  //     title: 'fhgjhk',
  //     key: 'gfhjklk',
  //     render: (values: any) => {
  //       const caption = values?.row?.gfhjklk ?? 'N/A'
  //       const truncatedName =
  //         caption?.length > 20 ? caption?.slice(0, 40) + '...' : caption
  //       return (
  //         <Row gap="sm" align="center">
  //           <Typography variant="p" fontSize="md" fontWeight="lg">
  //             {truncatedName}
  //           </Typography>
  //         </Row>
  //       )
  //     },
  //   },
  // ]
  // const data = [
  //   {
  //     caption: 'Apple iPhone 14 Pro Max',
  //     gfhjklk: 'Apple A16 Bionic',
  //     jkName: 'jk nbame demo 1',
  //   },
  //   {
  //     caption: 'Samsung Galaxy S23 Ultra',
  //     gfhjklk: 'Snapdragon 8 Gen 2',
  //     jkName: 'jk nbame demo 2',
  //   },
  //   {
  //     caption: 'Google Pixel 7 Pro',
  //     gfhjklk: 'Google Tensor G2',
  //     jkName: 'jk nbame demo 3',
  //   },
  //   {
  //     caption: 'OnePlus 11 5G',
  //     gfhjklk: 'Snapdragon 8 Gen 2',
  //     jkName: 'jk nbame demo 4',
  //   },
  //   {
  //     caption: 'Xiaomi 13 Pro',
  //     gfhjklk: 'Snapdragon 8 Gen 2',
  //     jkName: 'jk nbame demo 5',
  //   },
  //   {
  //     caption: 'Sony Xperia 1 IV',
  //     gfhjklk: 'Snapdragon 8 Gen 1',
  //     jkName: 'jk nbame demo 6',
  //   },
  //   {
  //     caption: 'Oppo Find X5 Pro',
  //     gfhjklk: 'Snapdragon 8 Gen 1',
  //     jkName: 'jk nbame demo 7',
  //   },
  // ]

  return (
    <Column align="center" justify="center" width="100%" gap="lg">
      <HighlightedLastWord
        fontSize="lg"
        fontWeight="1x"
        text="Good morning, Alisha Thapa"
        highlightCount={2}
      />
      <Dashcard data={dashcardData} />
      {/* <DataTable columns={columns} data={data} /> */}

      <div
        style={{
          width: '100%',
          display: 'grid',
        }}
      >
        {/* {dummyCustomers.map((customer) => (
          <CustomerInfoCard
            key={customer.email}
            customerName={customer.customerName}
            phoneNumber={customer.phoneNumber}
            email={customer.email}
            customerImage={customer.customerImage}
          />
        ))} */}
        <DashboardTable/>
        {/* {dummyCustomers.map((customer) => (
          <CustomerInfoCard
            key={customer.email}
            // doctorName={customer.DoctorName}
            phoneNumber={customer.phoneNumber}
            email={customer.email}
            // doctorImage={customer.DoctorImage}
          />
        ))} */}
      </div>
    </Column>
  )
}

export default Dashboard
