import Button from '@components/Button'
import FormField from '@components/FormField'
import { Column, Row } from '@components/Grid'

import Typography from '@components/Typography'
import styled from '@emotion/styled'
import theme from '@utils/theme'

import { Form, Formik } from 'formik'

const DefaultParcelPrie = () => {
  const fareCategories = [
    {
      weightType: '0.5-1/kg',
      fares: {
        documentsFarePerKm: '250',
        fragileFarePerKm: '250',
        giftFarePerKm: '100',
      },
    },
    {
      weightType: '5.1-10/kg',
      fares: {
        documentsFarePerKm: '250',
        fragileFarePerKm: '250',
        giftFarePerKm: '100',
      },
    },
    {
      weightType: '10.1-30/kg',
      fares: {
        documentsFarePerKm: '250',
        fragileFarePerKm: '250',
        giftFarePerKm: '100',
      },
    },
  ]

  const parcelCategories = [
    {
      label: 'Document',
      value: 'document',
    },
    {
      label: 'Fragile',
      value: 'fragile',
    },
    {
      label: 'Gift',
      value: 'gift',
    },
  ]

  return (
    <Column width="100%" gap="lg">
      <Typography fontSize="1x" fontWeight="2x">
        Trip Fare Setup
      </Typography>
      <Column padding="lg" background="white" width="100%" gap="2x">
        <Typography fontColor="primary" fontWeight="2x">
          Free Default Price (Bagmati Zone)
        </Typography>

        <Formik initialValues={{}} onSubmit={() => {}}>
          <FormWrapper>
            <Column gap="sm">
              <Typography fontSize="lg" fontWeight="1x">
                Available parcel category in this zone
              </Typography>
              <FormField
                name="parcelCategorires"
                type="checkbox"
                checkboxProps={{
                  checkboxElement: parcelCategories.map((element) => {
                    return {
                      label: (
                        <Typography key={element.value} fontSize="md">
                          {element.label}
                        </Typography>
                      ),
                      value: element.value,
                    }
                  }),
                }}
              />
            </Column>

            <Row gap="2x">
              <FormField type="text" label="Base Fare" name="basefare" />
              <FormField
                type="text"
                label="Canellation fee"
                name="cancellationfee"
              />
              <FormField type="text" label="Return fee (%)" name="returnfee" />
            </Row>
          </FormWrapper>
        </Formik>

        {/* Table header */}
        <Wrapper align="center" justify="space-between">
          <TableHeader background="ghostWhite">
            <Typography fontSize="lg">Fare</Typography>
            <Typography>Documents (per km)</Typography>
            <Typography>Fragile(km) </Typography>
            <Typography>Gifts(km) </Typography>
          </TableHeader>

          {fareCategories.map((category) => (
            <ColumnWrapper key={category.weightType}>
              <Typography align="center" fontSize="lg">
                {category.weightType}
              </Typography>
              <Button
                title={category.fares.documentsFarePerKm}
                bgColor="transparent"
                border="grey50"
                paddingX="sm"
                width="50%"
              />
              <Button
                title={category.fares.fragileFarePerKm}
                bgColor="transparent"
                border="grey50"
                paddingX="sm"
                width="50%"
              />
              <Button
                title={category.fares.giftFarePerKm}
                bgColor="transparent"
                border="grey50"
                paddingX="sm"
                width="50%"
              />
            </ColumnWrapper>
          ))}
        </Wrapper>

        <Row width="100%" align="right" justify="right">
          <Button
            title="Save"
            bgColor="primary"
            textColor="white"
            width="15%"
          />
        </Row>
      </Column>
    </Column>
  )
}

export default DefaultParcelPrie

const Wrapper = styled(Row)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  align-items: center;
`

const ColumnWrapper = styled(Column)`
  width: 100%;
  gap: ${theme.spacing['2x']}px;
  padding: ${theme.spacing['2x']}px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.grey50};
  border-right: none !important;
  border-left: none !important;
`
const FormWrapper = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
const TableHeader = styled(ColumnWrapper)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border: none;
  border-right: 1px solid ${theme.colors.grey50} !important;
`
