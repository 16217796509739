import { notification } from '@assets/svg'
import { Row } from '@components/Grid'
import Image from '@components/Image'
import Modal from '@components/Modal'
import Popover from '@components/Popover'
import SvgIcon from '@components/SvgIcon'
import { useState } from 'react'

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <Row
      background="white"
      width="100%"
      padding="lg"
      align="center"
      justify="flex-end"
      gap="md"
    >
      <SvgIcon
        src={notification}
        size={24}
        onClick={() => setIsModalOpen(true)}
      />
      <Popover content={<p>This Content Will be render in Popover.</p>}>
        <Image
          src="https://images.pexels.com/photos/2787341/pexels-photo-2787341.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt="logo"
          borderRadiusFull
          size={24}
        />
      </Popover>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Notification Modal"
      >
        <p>This is the notification modal!</p>
      </Modal>
    </Row>
  )
}

export default Header
