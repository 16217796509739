import { Outlet } from 'react-router-dom'
import { Sidebar } from './SideBar'
import Header from './Header'
import { Row, Column } from '@components/Grid'
import styled from '@emotion/styled'
import theme from '@utils/theme'

const PrivateLayout = () => {
  return (
    <Row>
      <ScrollbarWrapper height="100vh" border="grey50" width="260px">
        <Sidebar />
      </ScrollbarWrapper>

      <MainContentColumn>
        <HeaderWrapper flexShrink={0} width="100%">
          <Header />
        </HeaderWrapper>

        <ContentArea>
          <Outlet />
        </ContentArea>
      </MainContentColumn>
    </Row>
  )
}

export default PrivateLayout

// ScrollbarWrapper set to fixed
const ScrollbarWrapper = styled(Row)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 260px;
  overflow-y: auto;
  scrollbar-width: 4px;
  scrollbar-color: ${theme.colors.primary50} transparent;
  background-color: white;
  z-index: 2;
`

const MainContentColumn = styled(Column)`
  margin-left: 260px;
  flex-grow: 1;
  height: 100vh;
  overflow: hidden;
`

// Outlet container made scrollable
const ContentArea = styled(Row)`
  width: 100%;
  padding: 8px;
  flex-grow: 1;
  overflow-y: auto;
`
const HeaderWrapper = styled(Row)`
  min-height: 56px;
`
