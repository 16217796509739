import Button from '@components/Button'
import FormField from '@components/FormField'
import { Column, Row } from '@components/Grid'
import Typography from '@components/Typography'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import SvgIcon from '@components/SvgIcon'; 
import DataTable from '@components/DataTable';
import Breadcrum from '@components/Breadcrum';
import { bikeIcon, trashBin ,carIcon, arrowDownload,plusIcon,pencilEdit,testIcon} from '@assets/svg'
import {  ButtonsProps } from '@components/Breadcrum/interface';
import ToggleButton from '@components/FormField/ToggleButton';
const VehicleModelForm = () => {
  const columns = [

    {
      title: "Brand",
      key: "caption",
      render: (values: any) => {
        console.log(values,"valuesvaluesvalues")
        const caption = values?.row?.totalTrip  ?? "N/A";
        const truncatedName = caption?.length > 20 ? caption?.slice(0, 40) + "..." : caption;
        return (
          <Row  justify="center">
          <Column>         
            <SvgIcon src={caption}/>
          </Column>         
          </Row>
        );
      },
    },
    {
      title: "Model",
      key: "caption",
      render: (values: any) => {
        console.log(values,"valuesvaluesvalues")
        const caption = values?.row?.name  ?? "N/A";
        const truncatedName = caption?.length > 20 ? caption?.slice(0, 40) + "..." : caption;
        return (
          <Row gap="sm" justify="center">
            {truncatedName}
          </Row>
        );
      },
    },
    {
      title: "Status",
      key: "tripStatus",
      render: (values: any) => {
        const CustomerName = values?.row?.TripStatus ?? "N/A";
        let textColor;
        let bgColor = 'primary50';  
    
        switch (CustomerName) {
          case "Pending":
          case "Cancelled":
            textColor = "primary"; 
            break;
          case "Ongoing":
            textColor = "SkyDark"; 
            bgColor= "lightSky";
            break;
          case "Completed":
            textColor = "green500"; 
            bgColor= "green50";
            break;
          default:
            textColor = "primary"; 
        }
    
        return (
          <>
          {/* <Button
            title={CustomerName}
            type='button'
            outline='true'
            textColor={textColor}  
            bgColor={bgColor}     
          /> */}
        <Row gap="sm" justify="center">
          <ToggleButton
            name="exampleToggle"
            defaultChecked={true}
            onChange={(checked) => console.log('Toggle is now:', checked)}
          />
        </Row>

          {/* <RadioButton
            labelArray={[
              { value: 'option1', title: 'Option 1', image: '/path/to/image1.png' },
              { value: 'option2', title: 'Option 2', image: '/path/to/image2.png' },
              { value: 'option3', title: 'Option 3' },  // No image for this option
            ]}
            size="1x"  // Assuming "1x" is a valid key in your theme.fontSizes
            name="exampleRadioGroup"
            onChange={(value) => console.log('Selected value:', value)}
            defaultValue="option1"
          /> */}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (values: any) => {
        // const CustomerName = values?.row?.status  ?? "N/A";
        return (
          <Row gap="sm" justify="center">
          <SvgIcon src={pencilEdit}/>
          <SvgIcon src={trashBin}/>
          </Row>
        );
      },
    },
  ];

  const buttons: ButtonsProps[] = [
    {
      title: 'All',
      type: 'button', 
      outline: 'true', 
      color: 'primary',
      textColor: "primary",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'primary50',
    },
    {
      title: 'Active',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'white100',
    },
   
    {
      title: 'InActive',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      bgColor: 'white100',
      wrapperPadding: '4px 0px 0px 0px',
    },
    {
      title: 'Download',
      type: 'button',
      outline: 'true',
      border: 'primary',
      color: 'primary',
      frontIcon: <SvgIcon src={arrowDownload} />,
      textColor: "primary",
      wrapperPadding: '0px 0px 0px 20px',
      bgColor: 'white100',
    },
    {
      title: 'Add New Vehicle',
      type: 'button',
      outline: 'true',
      border: 'primary',
      color: 'primary',
      frontIcon: <SvgIcon src={plusIcon} />,
      textColor: "white",
      // wrapperPadding: '0px 0px 0px 20px',
      bgColor: 'primary',
    },
  ];

  const staticData = [
    { "name": "Nx 200","registerDate": "August 1 2024", totalTrip: "Honda", "TripStatus": "Pending", "status": "Active" },
    { "name": "Nx 200","registerDate": "August 1 2024", totalTrip: "Honda", "TripStatus": "Ongoing", "status": "Active" },
    { "name": "Nx 200","registerDate": "August 1 2024", totalTrip: "Honda", "TripStatus": "Cancelled", "status": "Active" },
  ];
  return (
    <Column background="white" padding="lg" radius="sm" gap="lg" width="100%">
      <Typography fontSize="lg" fontWeight="xlg">
        Add Vehicle Model
      </Typography>
      <Formik initialValues={{}} onSubmit={() => {}}>
        <FormWrapper>
          <Row>
            <FormField
              type="select"
              label="Vehicle Brand"
              name="brand"
              placeholder="Select vehicle brand"
              select={{
                titleAsKey: 'label',
                valueAsKey: 'value',
              }}
              data={[
                { value: 'bajaj', label: 'Bajaj' },
                { value: 'yamaha', label: 'Yamaha' },
                { value: 'tvs', label: 'TVS' },
              ]}
            />
            <FormField
              type="text"
              label="Vehicle Model"
              placeholder="Ex: R15"
              name="model"
            />
          </Row>
          <Row width="100%" justify="flex-end">
            <Button title="Add" width="15%" radius="sm" textColor="white"  bgColor="primary" />
          </Row>
        </FormWrapper>
      </Formik>
      <Typography fontSize="2x">Vehicle Model List</Typography>
      <DataTable
        data={staticData}
        search
        columns={columns}
        onFilterChange={(filter) => console.log('Filter changed:', filter)}
        breadcrum={<Breadcrum title="" buttons={buttons} />}
      />
    </Column>
  )
}

export default VehicleModelForm

const FormWrapper = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
