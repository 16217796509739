import Image from '@components/Image'
import { ChangeEvent, useRef, useState } from 'react'
import SvgIcon from '@components/SvgIcon'
import { fileUpload } from '@assets/svg'
import { Row } from '@components/Grid'

const FileUpload = ({
  onChange,
}: {
  onChange: (fileInfo: { file: File | null; fileName: string | null }) => void // Accept an object with file and fileName
}) => {
  const [base64Url, setBase64Url] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const result = e?.target?.result as string
        setBase64Url(result)
        onChange({ file, fileName: file.name }) // Pass an object with file and fileName
      }
      reader.readAsDataURL(file)
    } else {
      onChange({ file: null, fileName: null }) // Handle the case where no file is selected
    }
  }

  const triggerFileInput = () => {
    fileInputRef.current?.click()
  }

  return (
    <Row width="100%" gap="sm">
      <input
        ref={fileInputRef}
        accept="image/png,image/jpeg"
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <Row
        // width='100%'
        gap="sm"
        onClick={triggerFileInput}
        align="center"
        justify="center"
        border="grey50"
        height="120px"
        padding="2x"
      >
        <SvgIcon src={fileUpload} size={18} /> Upload Image or file
      </Row>

      {base64Url && <Image size={120} src={base64Url} alt="Image Preview" />}
    </Row>
  )
}

export default FileUpload
