import Button from '@components/Button'
import FormField from '@components/FormField'
import { Column, Row } from '@components/Grid'
import Typography from '@components/Typography'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'

const VehicleTypeForm = () => {
  const handleSubmit = async (values, { resetForm }) => {
    console.log(values)
    resetForm()
  }

  return (
    <>
      <Column background="white" padding="lg" radius="sm" gap="lg" width="100%">
          <Typography fontSize="lg" fontWeight="xlg">
            Add Vehicle Type
          </Typography>
          <Formik
            initialValues={{ vehicle: '', image: null, vehicleType: '' }}
            onSubmit={handleSubmit}
          >
          <FormWrapper>
            <Row>
              <FormField
                type="text"
                label="Vehicle Name"
                placeholder="Ex: Bike"
                name="vehicle"
              />

              <FormField
                label="Vehicle Image"
                type="file"
                name="image"
                file={{ type: 'single', fileStyle: 'button' }}
              />
            </Row>

            <Row width="100%" justify="flex-end">
              <Button
                title="Add"
                width="15%"
                radius="sm"
                textColor="white"
                type="submit"
                bgColor="primary"
              />
            </Row>
          </FormWrapper>
        </Formik>
      </Column>
    </>
  )
}

export default VehicleTypeForm

const FormWrapper = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
