import Image from '@components/Image'
import { Breakpoints } from '@constants/global.constant'
import styled from '@emotion/styled'
import theme from '@utils/theme'

export const StyleLabel = styled.label`
  font-size: ${theme.fontSizes?.md}px;
  color: ${theme.colors?.grey400};
  font-weight: 400;
  display: flex;
  width: max-content;
`

export const StyleInputWrapper = styled.div<any>`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => props.direction || 'column'};
  justify-content: ${(props) =>
    props.direction === 'row' ? 'space-between' : 'initial'};
  align-items: ${(props) => (props.direction === 'row' ? 'center' : 'initial')};
  ${(props) =>
    props.direction !== 'row'
      ? `row-gap: ${props.rowGap || '0'}`
      : `column-gap: ${props.columnGap || '0'}`};
  // align-items: ${(props) => props.align || 'none'};
  ${(props) => props.wrapperStyle}
`

export const StyleInput = styled.input<any>`
  min-height: 35px;
  border: 1px solid ${theme.colors.grey50};
  width: 100%;
  border-radius: 4px;
  text-indent: 8px;
  font-size: ${(props) => props.theme.fontSizes['lg']}px;
  padding: 4px 0;
  outline: none;
  &::placeholder {
    font-size: ${(props) => props.theme.fontSizes['lg']}px;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none; /* Firefox */
    appearance: none; /* Standard */
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none; /* Firefox */
    appearance: none; /* Standard */
  }

  ${(props) => `${props.style}`}
`

export const StyledPasswordIconWrapper = styled.div`
  height: 35px;
  position: absolute;
  right: 0px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  z-index: 1;
`

export const StyleSingleImageWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
`

export const StyleMultipleImageWrapper = styled.div<any>`
  display: flex;
  column-gap: 12px;
  flex-flow: wrap;
  justify-content: ${(props) => (props.isCenter ? 'center' : 'flex-start')};
`

export const StyleImageLabel = styled.label<any>`
  border-radius: 4px;
  border: 1px dashed ${(props) => props.theme.colors?.gray100};
  min-height: 50px;
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes['lg']}px;
  color: ${(props) => props.theme.colors?.primary};
  padding: 12px 20px;
  cursor: pointer;
  justify-content: space-between;
`

export const StyleMultipleImageLabel = styled.label<any>`
  border-radius: 8px;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors?.gray100};
  color: ${(props) => props?.theme.colors?.primary};
`
export const StyleMultipleImageItem = styled.div`
  position: relative;
  z-index: 1;
`

export const StylePreviewImage = styled(Image)`
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 4px;
`

export const StyleMultipleImagePreview = styled(Image)`
  height: 60px;
  width: 60px;
  border-radius: 4px;
`
export const StyleMultipleImageRemoveWrapper = styled.div`
  color: ${theme.colors?.red300};
  position: absolute;
  top: -5px;
  right: -5px;
`

export const StyleTextArea = styled.textarea<any>`
  width: 100%;
  font-size: ${(props) => props.theme.fontSizes['sm']}px;
  border-radius: 4px;
  border: ${(props) => props.border || 0};
  height: ${(props) => props.height || 'auto'};
  outline: none;
  padding: 5px 10px;
  resize: none;

  &::placeholder {
    font-size: ${(props) => props.theme.fontSizes['sm']}px;
  }
  ${(props) => `${props.style}`}
`
interface StyleChooseButtonWrapperProps {
  $multiLine: 0 | 1
}

export const StyleChooseButtonWrapper = styled.div<StyleChooseButtonWrapperProps>`
  display: flex;
  column-gap: 12px;
  row-gap: 10px;
  overflow: auto;
  flex-wrap: ${(props) => (props.$multiLine ? 'wrap' : 'unset')};
  scrollbar-width: none;
  white-space: nowrap;
  max-width: 100%;
`

export const StyleButton = styled.div<any>`
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      !!props.active
        ? props.theme.colors.primary100
        : props.theme.colors.gray100};
  padding: 4px 10px;
  font-size: ${(props) => props.theme.fontSizes['sm']}px;
  background: ${(props) =>
    !!props.active ? props.theme.colors.primary100 : 'transparent'};
  color: ${(props) =>
    !!props.active
      ? props.theme.colors.primary
      : props.ismoment
        ? props.theme.colors.white500
        : props.theme.colors.gray400};
  transition: 0.2s;
  display: flex;
  column-gap: 5px;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.colors.primary100};
    color: ${(props) => props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primary100};
  }
`

export const StyleFileUploadButtonLabel = styled.label<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.isMobile
      ? props.theme.colors.black
      : `${props.theme.colors.primary}`} !important;
  padding: ${(props) => props.theme.spacing.sm}px 0px;
  cursor: pointer;
  border-radius: 8px;
  width: ${(props) => (props.isMobile ? 'calc(100vw - 32px)' : '138px')};
  max-width: ${(props) => (props.isMobile ? 'calc(100vw - 32px)' : '138px')};
  font-size: ${(props) => props.theme.fontSizes['sm']}px !important;
  color: ${(props) => props.theme.colors.white} !important;
`

export const StylePreviewVideo = styled.video`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
`

export const StyleMultipleVideoPreview = styled.video`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
`

export const BackgroundIcon = styled.label<any>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.black50} !important;
  cursor: pointer;

  @media (max-width: ${Breakpoints.mobile}) {
    width: 35px;
    height: 35px;
  }
`

export const FileUploadIconText = styled.label<any>`
  background: none !important;
`
