import Button from '@components/Button'
import { Car, MotorBike } from '@assets/images'
import FormField from '@components/FormField'
import { Column, Row } from '@components/Grid'
import MapComponent from '@components/MapComponent'
import Typography from '@components/Typography'
import styled from '@emotion/styled'
import theme from '@utils/theme'
import { Form, Formik } from 'formik'
import { customerAnalyticsData } from '@assets/dummyData/customerAnalytics'
import Dashcard from '@components/Dashcard'
import DataTable from '@components/DataTable';
import Breadcrum from '@components/Breadcrum';
import SvgIcon from '@components/SvgIcon'; 
import { searchIcon, trashBin ,eyeIcon, arrowDownload,plusIcon,pencilEdit,testIcon} from '@assets/svg'
import {  ButtonsProps } from '@components/Breadcrum/interface';
import ToggleButton from '@components/FormField/ToggleButton';
const ZoneSetup = () => {
  const ChooseOption = [
    {
      title: 'Ride Sharing',
      value: 'ride_sharing',
      // image: MotorBike,
    },
    {
      title: 'Parcel',
      value: 'parcel',
      image: Car,
    },
  ]
  const columns = [
    {
      title: "Zone Name",
      key: "caption",
      render: (values: any) => {
        console.log(values,"valuesvaluesvalues")
        const caption = values?.row?.name  ?? "N/A";
        const truncatedName = caption?.length > 20 ? caption?.slice(0, 40) + "..." : caption;
        return (
          <Row gap="sm" justify="center">
            {truncatedName}
          </Row>
        );
      },
    },
    {
      title: "Trip Request Volume",
      key: "categories",
      render: (values: any) => {
        const CustomerName = values?.row?.mobileNumber  ?? "N/A";
        return (
          <Row gap="sm" justify="center">
           {CustomerName}
          </Row>
        );
      },
    },
    
  
    {
      title: "Status",
      key: "tripStatus",
      render: (values: any) => {
        const CustomerName = values?.row?.TripStatus ?? "N/A";
        let textColor;
        let bgColor = 'primary50';  
    
        switch (CustomerName) {
          case "Pending":
          case "Cancelled":
            textColor = "primary"; 
            break;
          case "Ongoing":
            textColor = "SkyDark"; 
            bgColor= "lightSky";
            break;
          case "Completed":
            textColor = "green500"; 
            bgColor= "green50";
            break;
          default:
            textColor = "primary"; 
        }
    
        return (
          <>
          {/* <Button
            title={CustomerName}
            type='button'
            outline='true'
            textColor={textColor}  
            bgColor={bgColor}     
          /> */}
        <Row gap="sm" justify="center">
          <ToggleButton
            name="exampleToggle"
            defaultChecked={true}
            onChange={(checked) => console.log('Toggle is now:', checked)}
          />
        </Row>

          {/* <RadioButton
            labelArray={[
              { value: 'option1', title: 'Option 1', image: '/path/to/image1.png' },
              { value: 'option2', title: 'Option 2', image: '/path/to/image2.png' },
              { value: 'option3', title: 'Option 3' },  // No image for this option
            ]}
            size="1x"  // Assuming "1x" is a valid key in your theme.fontSizes
            name="exampleRadioGroup"
            onChange={(value) => console.log('Selected value:', value)}
            defaultValue="option1"
          /> */}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (values: any) => {
        // const CustomerName = values?.row?.status  ?? "N/A";
        return (
          <Row gap="sm" justify="center">
          <SvgIcon src={pencilEdit}/>
          <SvgIcon src={trashBin}/>
          </Row>
        );
      },
    },
  ];

  const buttons: ButtonsProps[] = [
    {
      title: 'All',
      type: 'button', 
      outline: 'true', 
      color: 'primary',
      textColor: "primary",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'primary50',
    },
    {
      title: 'Active',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'white100',
    },
   
    {
      title: 'InActive',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      bgColor: 'white100',
      wrapperPadding: '4px 0px 0px 0px',
    },
    {
      title: 'Download',
      type: 'button',
      outline: 'true',
      border: 'primary',
      color: 'primary',
      frontIcon: <SvgIcon src={arrowDownload} />,
      textColor: "primary",
      wrapperPadding: '0px 0px 0px 20px',
      bgColor: 'white100',
    },
  ];

  const staticData = [
    { "name": "Bagmati","mobileNumber": "Low", "totalTrip": "7","registerDate": "August 1 2024", "TripStatus": "Pending", "status": "Active" },
    { "name": "Karnali","mobileNumber": "Medium", "totalTrip": "7","registerDate": "August 1 2024", "TripStatus": "Ongoing", "status": "Active" },
    { "name": "Narayani","mobileNumber": "high", "totalTrip": "7","registerDate": "August 1 2024", "TripStatus": "Cancelled", "status": "Active" },
  ];
  return (
    <Column gap="sm" width="100%">
      <Typography fontSize="2x">Zone Setup</Typography>
      <Column background="white" padding="md" radius="sm" width="100%" gap="md">
        <Formik initialValues={{}} onSubmit={() => {}}>
          <FormWrapper>
            <Row gap="lg" width="100%">
            <Column width="50%">
            <FormField
              type="text"
              name="location"
              label="Zone Name"
              placeholder="Ex:Bagmati"
            />
            </Column>
           <Column>
              <Typography fontSize="lg" fontWeight="xlg">
                Choose Services Availability
              </Typography>
              <FormField
                type="radio"
                name="vehicleType"
                radioProps={{
                  labelArray: ChooseOption.map(({ title, image, value }) => ({
                    title,
                    image,
                    value,
                  })),

                  size: '1x',
                }}
              />
              </Column>
            </Row>
            <MapComponent />
            <Row width="100%" justify="flex-end" align="flex-end">
              <Button
                title="Submit"
                width="15%"
                textColor="white"
                padding="md"
                radius="md"
                bgColor="primary"
              />
            </Row>
          </FormWrapper>
        </Formik>
      <Typography fontColor="grey500" fontSize="2x" fontWeight='xlg'>Zone List</Typography>
      <DataTable
        data={staticData}
        search
        columns={columns}
        onFilterChange={(filter) => console.log('Filter changed:', filter)}
        breadcrum={<Breadcrum title="" buttons={buttons} />}
      />
      </Column>
    </Column>

  )
}

export default ZoneSetup

const FormWrapper = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md}px;
`
