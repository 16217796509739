import { driverAnalyticsData } from '@assets/dummyData/driverAnalyticsData'
import Dashcard from '@components/Dashcard'
import Typography from '@components/Typography'
import { Column, Row } from '@components/Grid';
import DataTable from '@components/DataTable';
import Breadcrum from '@components/Breadcrum';
import SvgIcon from '@components/SvgIcon'; 
import { trashBin ,eyeIcon, arrowDownload,plusIcon,testIcon} from '@assets/svg'
import ToggleButton from '@components/FormField/ToggleButton';
import {  ButtonsProps } from '@components/Breadcrum/interface';
import { useNavigate } from 'react-router-dom';

const DriverManagement = () => {
  const navigate = useNavigate();
  const columns = [
    {
      title: "Name",
      key: "caption",
      render: (values: any) => {
        console.log(values,"valuesvaluesvalues")
        const caption = values?.row?.name  ?? "N/A";
        const truncatedName = caption?.length > 20 ? caption?.slice(0, 40) + "..." : caption;
        return (
          <Row  justify="center">
          <Column>         
            <SvgIcon src={testIcon}/>
          </Column>
          <Column>
          <Row  justify="center">
            {truncatedName}
          </Row>
          <Row  justify="center">
            ibijushrestha@gmail.com
          </Row>
          </Column>
          </Row>
        );
      },
    },
    {
      title: "Moile Number",
      key: "categories",
      render: (values: any) => {
        const CustomerName = values?.row?.mobileNumber  ?? "N/A";
        return (
          <Row gap="sm" justify="center">
           {CustomerName}
          </Row>
        );
      },
    },
    {
      title: "Total Trip",
      key: "links",
      render: (values: any) => {
        const CustomerName = values?.row?.totalTrip  ?? "N/A";
        return (
          <Row gap="sm" justify="center">
           {CustomerName}
          </Row>
        );
      },
    },
    {
      title: "Earning",
      key: "price",
      render: (values: any) => {
        const CustomerName = values?.row?.earning  ?? "N/A";
        return (
          <Row gap="sm" justify="center">
           {CustomerName}
          </Row>
        );
      },
    },
    {
      title: "Status",
      key: "tripStatus",
      render: (values: any) => {
        const CustomerName = values?.row?.TripStatus ?? "N/A";
        let textColor;
        let bgColor = 'primary50';  
    
        switch (CustomerName) {
          case "Pending":
          case "Cancelled":
            textColor = "primary"; 
            break;
          case "Ongoing":
            textColor = "SkyDark"; 
            bgColor= "lightSky";
            break;
          case "Completed":
            textColor = "green500"; 
            bgColor= "green50";
            break;
          default:
            textColor = "primary"; 
        }
    
        return (
          <>
        <Row gap="sm" justify="center">
          <ToggleButton
            name="exampleToggle"
            defaultChecked={true}
            onChange={(checked) => console.log('Toggle is now:', checked)}
          />
        </Row>
          </>
        );
      },
    },
    
    {
      title: "Action",
      key: "action",
      render: (values: any) => {
        const CustomerName = values?.row?.status  ?? "N/A";
        return (
          <Row gap="sm" justify="center">
          <div  
            onClick={() => {
              navigate("/user-management/driver/view");
            }}
            >
            <SvgIcon src={eyeIcon}/>
          </div>
          <SvgIcon src={trashBin}/>
          </Row>
        );
      },
    },
  ];
  const buttons: ButtonsProps[] = [
    {
      title: 'All',
      type: 'button', 
      outline: 'true', 
      color: 'primary',
      textColor: "primary",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'primary50',
    },
    {
      title: 'Pending',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'white100',
    },
    {
      title: 'Ongoing',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'white100',
    },
    {
      title: 'Accepted',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      bgColor: 'white100',
      wrapperPadding: '4px 0px 0px 0px',
    },
    {
      title: 'Completed',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'white100',
    },
    {
      title: 'Download',
      type: 'button',
      outline: 'true',
      border: 'primary',
      color: 'primary',
      frontIcon: <SvgIcon src={arrowDownload} />,
      textColor: "primary",
      wrapperPadding: '0px 0px 0px 20px',
      bgColor: 'white100',
    },
    {
      title: 'Add Download',
      type: 'button',
      outline: 'true',
      border: 'primary',
      color: 'primary',
      frontIcon: <SvgIcon src={plusIcon} />,
      textColor: "white",
      bgColor: 'primary',
    },
  ];
  const staticData = [
    { "name": "Biju shrestha","mobileNumber": "9876565678", "totalTrip": "7","earning": 190, "TripStatus": "Pending", "status": "Active" },
    { "name": "Biju shrestha","mobileNumber": "9876565678", "totalTrip": "7","earning": 190, "TripStatus": "Ongoing", "status": "Active" },
    { "name": "Biju shrestha","mobileNumber": "9876565678", "totalTrip": "7","earning": 190, "TripStatus": "Cancelled", "status": "Active" },
    { "name": "Biju shrestha","mobileNumber": "9876565678", "totalTrip": "7","earning": 190, "TripStatus": "Completed", "status": "Active" },
    { "name": "Biju shrestha","mobileNumber": "9876565678", "totalTrip": "7","earning": 190, "TripStatus": "Completed", "status": "Active" },
    { "name": "Biju shrestha","mobileNumber": "9876565678", "totalTrip": "7","earning": 190, "TripStatus": "Completed", "status": "Active" },
  ];

  return (
    <Column gap="md" width="100%">
      <Typography fontColor="grey500" fontSize="2x" fontWeight='xlg'>Driver Analytics</Typography>
      <Dashcard data={driverAnalyticsData} row={6} />
      <Typography fontColor="grey500" fontSize="2x" fontWeight='xlg'>Driver List</Typography>
      <DataTable
        data={staticData}
        search
        columns={columns}
        onFilterChange={(filter) => console.log('Filter changed:', filter)}
        breadcrum={<Breadcrum title="" buttons={buttons} />}
      />
    </Column>
  )
}

export default DriverManagement
