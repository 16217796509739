import { Column } from '@components/Grid'
import Typography from '@components/Typography'
import { Form, Formik } from 'formik'
import FormField from '@components/FormField'
import Image from '@components/Image'
import { Logo } from '@assets/images'
import Button from '@components/Button'
import styled from '@emotion/styled'
import { loginValidationSchema } from '@schemas/login.validation'

const LoginForm = () => {
  const initialValues = {
    email: '',
    password: '',
  }
  const handleSubmit = async (values, { resetForm }) => {
    console.log(values)
    resetForm()
  }
  return (
    <MainWrapper align="center" justify="center" height="100vh">
      <Column align="center" justify="center" width="100%">
        <Image src={Logo} alt="logo" />
        <Column
          background="white"
          padding="lg"
          gap="3x"
          width="380px"
          radius="xs"
          border="grey50"
        >
          <Typography align="center" fontSize="1x" fontWeight="xlg">
            Welcome to Admin Panel
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={loginValidationSchema}
          >
            <FormWrapper>
              <FormField
                type="email"
                label="Email"
                placeholder="user@gmail.com"
                name="email"
                styledLabel={true}
              />
              <FormField
                type="password"
                label="Password"
                placeholder="Enter password"
                name="password"
                styledLabel={true}
              />{' '}
              <Button
                title="Login"
                bgColor="primary"
                textColor="white"
                radius="xs"
                padding="md"
                fontSize="md"
                type="submit"
              />
            </FormWrapper>
          </Formik>
        </Column>
      </Column>
    </MainWrapper>
  )
}

export default LoginForm

const MainWrapper = styled(Column)`
  background: radial-gradient(
    70.71% 99.44% at 50% 50%,
    #f0f9ff 0%,
    #f3f7ff 20%,
    #f7f5ff 40%,
    #fbf3fc 60%,
    #fef2f7 80%,
    #fff1f2 100%
  );
`

const FormWrapper = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
