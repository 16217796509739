import { useState } from 'react'
import Button from '@components/Button'
import { Column, Row } from '@components/Grid'
import VehicleTypeForm from '@components/Forms/Vehicle/VehicleTypeForm'
import VehicleModelForm from '@components/Forms/Vehicle/VehicleModelForm'
import VehicleBrandForm from '@components/Forms/Vehicle/VehicleBrandForm'
import FormField from '@components/FormField'
import Typography from '@components/Typography'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import { vehicleData } from '@assets/dummyData/driverAnalyticsData'
import Dashcard from '@components/Dashcard'
import MapComponent from '@components/MapComponent'
import theme from '@utils/theme'
import { customerAnalyticsData } from '@assets/dummyData/customerAnalytics'
import DataTable from '@components/DataTable';
import Breadcrum from '@components/Breadcrum';
import SvgIcon from '@components/SvgIcon'; 
import { bikeIcon, trashBin ,carIcon, arrowDownload,plusIcon,pencilEdit,testIcon} from '@assets/svg'
import {  ButtonsProps } from '@components/Breadcrum/interface';
import ToggleButton from '@components/FormField/ToggleButton';
const VehicleSetup = () => {
  const [activeButton, setActiveButton] = useState('Vehicle type')

  const columns = [

    {
      title: "Vehicle Icon",
      key: "caption",
      render: (values: any) => {
        console.log(values,"valuesvaluesvalues")
        const caption = values?.row?.totalTrip  ?? "N/A";
        const truncatedName = caption?.length > 20 ? caption?.slice(0, 40) + "..." : caption;
        return (
          <Row  justify="center">
          <Column>         
            <SvgIcon src={caption}/>
          </Column>         
          </Row>
        );
      },
    },
    {
      title: "Vehicle Name",
      key: "caption",
      render: (values: any) => {
        console.log(values,"valuesvaluesvalues")
        const caption = values?.row?.name  ?? "N/A";
        const truncatedName = caption?.length > 20 ? caption?.slice(0, 40) + "..." : caption;
        return (
          <Row gap="sm" justify="center">
            {truncatedName}
          </Row>
        );
      },
    },
    {
      title: "Status",
      key: "tripStatus",
      render: (values: any) => {
        const CustomerName = values?.row?.TripStatus ?? "N/A";
        let textColor;
        let bgColor = 'primary50';  
    
        switch (CustomerName) {
          case "Pending":
          case "Cancelled":
            textColor = "primary"; 
            break;
          case "Ongoing":
            textColor = "SkyDark"; 
            bgColor= "lightSky";
            break;
          case "Completed":
            textColor = "green500"; 
            bgColor= "green50";
            break;
          default:
            textColor = "primary"; 
        }
    
        return (
          <>
            <Row gap="sm" justify="center">
              <ToggleButton
                name="exampleToggle"
                defaultChecked={true}
                onChange={(checked) => console.log('Toggle is now:', checked)}
              />
            </Row>
            {/* <RadioButton
              labelArray={[
                { value: 'option1', title: 'Option 1', image: '/path/to/image1.png' },
                { value: 'option2', title: 'Option 2', image: '/path/to/image2.png' },
                { value: 'option3', title: 'Option 3' },  // No image for this option
              ]}
              size="1x"  
              name="exampleRadioGroup"
              onChange={(value) => console.log('Selected value:', value)}
              defaultValue="option1"
            /> */}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (values: any) => {
        return (
          <Row gap="sm" justify="center">
          <SvgIcon src={pencilEdit}/>
          <SvgIcon src={trashBin}/>
          </Row>
        );
      },
    },
  ];

  const buttons: ButtonsProps[] = [
    {
      title: 'All',
      type: 'button', 
      outline: 'true', 
      color: 'primary',
      textColor: "primary",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'primary50',
    },
    {
      title: 'Active',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      wrapperPadding: '4px 0px 0px 0px',
      bgColor: 'white100',
    },
   
    {
      title: 'InActive',
      type: 'button',
      outline: 'true',
      color: 'primary',
      border: 'grey50',
      textColor: "grey400",
      bgColor: 'white100',
      wrapperPadding: '4px 0px 0px 0px',
    },
    {
      title: 'Download',
      type: 'button',
      outline: 'true',
      border: 'primary',
      color: 'primary',
      frontIcon: <SvgIcon src={arrowDownload} />,
      textColor: "primary",
      wrapperPadding: '0px 0px 0px 20px',
      bgColor: 'white100',
    },
    {
      title: 'Add New Vehicle',
      type: 'button',
      outline: 'true',
      border: 'primary',
      color: 'primary',
      frontIcon: <SvgIcon src={plusIcon} />,
      textColor: "white",
      // wrapperPadding: '0px 0px 0px 20px',
      bgColor: 'primary',
    },
  ];

  const staticData = [
    { "name": "Hond-Elevate","registerDate": "August 1 2024", totalTrip: carIcon, "TripStatus": "Pending", "status": "Active" },
    { "name": "Hond-Elevate","registerDate": "August 1 2024", totalTrip: bikeIcon, "TripStatus": "Ongoing", "status": "Active" },
    { "name": "Hond-Elevate","registerDate": "August 1 2024", totalTrip: carIcon, "TripStatus": "Cancelled", "status": "Active" },
  ];
  const buttonConfigs = [
    { title: 'Vehicle type', key: 'type' },
    { title: 'Vehicle brand', key: 'brand' },
    { title: 'Vehicle model', key: 'model' },
  ]

  const renderedForm = () => {
    switch (activeButton) {
      case 'Vehicle type':
        return <VehicleTypeForm />
      case 'Vehicle brand':
        return <VehicleBrandForm />
      case 'Vehicle model':
        return <VehicleModelForm />
      default:
        return null
    }
  }
  return (
    <Column gap="sm" width="100%">
      <Row width="100%" gap="sm">
        {buttonConfigs.map((button) => (
          <Button
            key={button.key}
            title={button.title}
            bgColor={activeButton === button.title ? 'primary50' : 'white'}
            textColor={activeButton === button.title ? 'primary' : 'grey400'}
            radius="sm"
            width="fit-content"
            // borderColor={activeButton !== button.title && 'grey50'}
            onClick={() => setActiveButton(button.title)}
          />
        ))}
      </Row>
      <Row width="100%">{renderedForm()}</Row>
      <Row style={{padding: "20px 0px 0px 0px"}}>
      <Typography fontColor="grey500" fontSize="2x" fontWeight='xlg'>Vehicle Type List</Typography>
      </Row>
      <DataTable
        data={staticData}
        search
        columns={columns}
        onFilterChange={(filter) => console.log('Filter changed:', filter)}
        breadcrum={<Breadcrum title="" buttons={buttons} />}
      />

    </Column>
  )
}

export default VehicleSetup
