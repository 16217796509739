import { forwardRef, useEffect, useState } from 'react'
import { Field } from 'formik'

import Typography from '../Typography'
import Select from './Select'
import Input from './Input'
// import PhoneNumber from './PhoneNumber'
import Password from './Password'
import FileUpload from './FileUpload'
import { FormFieldProps } from './interface'
import Textarea from './Textarea'
import ChooseButton from './ChooseButton'
import Switchs from './Switch'
import RadioButton from './RadioButton'
import RangeInputField from './Range'
import Checkbox from './Checkbox'
import DropDown from './DropDown'
import SearchDropdown from './SearchDropdown'
import StyledInput from './StyledInputField'
import FileUploadForMobile from './FileUploadForMobile'
import styled from '@emotion/styled'
import { Column, Row } from '@components/Grid'
import { StyleInputWrapper, StyleLabel } from './style'
import theme from '@utils/theme'

const FormField = (
  {
    type,
    label,
    required,
    name,
    placeholder,
    onChange,
    file,
    direction,
    data,
    select,
    buttonStyle,
    icon,
    iconText,
    disabled = false,
    style,
    id,
    // align,
    border,
    isVideoEnabled,
    radioProps,
    wrapperStyle,
    rangeProps,
    checkboxProps,
    dropDownProps,
    searchDropDownProps,
    height,
    styledLabel = false,
    onlyVideo = false,
    // showIcon = false,
    ...restProps
  }: FormFieldProps,
  ref: any,
) => {
  const [styledLabelRef, setStyledLabelRef] = useState(styledLabel)

  useEffect(() => {
    if (direction === 'row') {
      setStyledLabelRef(false)
    } else if (styledLabel === false) {
      setStyledLabelRef(false)
    } else {
      setStyledLabelRef(true)
    }
  }, [direction, styledLabel])

  return (
    <>
      <Column
        width="100%"
        style={{
          overflow: 'visible',
        }}
      >
        <Field name={name}>
          {({ field, meta, form }: any) => {
            return (
              <StyleInputWrapper
                direction={direction}
                wrapperStyle={wrapperStyle}
              >
                {!!label && !styledLabelRef && (
                  <Row style={{ minWidth: '30%' }}>
                    <StyleLabel>{label}</StyleLabel>
                    {!!required && (
                      <Typography
                        content="*"
                        fontSize="sm"
                        fontColor="red300"
                      />
                    )}
                  </Row>
                )}
                <Column
                  width="100%"
                  style={{
                    overflow: 'visible',
                  }}
                >
                  <StyledFormInput
                    stylelabel={styledLabelRef ? 1 : 0}
                    type={{ type }}
                    hasvalue={field.value ? 1 : 0}
                  >
                    {type === 'select' && typeof data !== 'string' ? (
                      <Select
                        select={select}
                        name={name}
                        placeholder={placeholder}
                        type={type}
                        data={data}
                        onChange={(event) => {
                          !!onChange && onChange(event.target.value)
                          form.setFieldValue(name, event.target.value)
                        }}
                        value={field.value}
                      />
                    ) : // : type === 'phoneNumber' ? (
                    //   <PhoneNumber
                    //     placeholder={placeholder || ''}
                    //     form={form}
                    //     onChange={(value: any) => {
                    //       !!onChange && onChange(value)
                    //       !!form?.setFieldValue &&
                    //         form?.setFieldValue(name, value?.nationalNumber)
                    //       !!form?.setFieldValue &&
                    //         form?.setFieldValue(
                    //           'countryCode',
                    //           value?.countryCode,
                    //         )
                    //       !!form?.setFieldValue &&
                    //         form?.setFieldValue(
                    //           'countryCallingCode',
                    //           `${value?.callingCode}`,
                    //         )
                    //     }}
                    //     value={field.value}
                    //     disabled={disabled}
                    //   />
                    // )
                    type === 'password' ? (
                      <Password
                        name={name}
                        placeholder={placeholder || ''}
                        type={type}
                        onChange={(event) => {
                          !!onChange && onChange(event.target.value)
                          form.setFieldValue(name, event.target.value)
                        }}
                        value={field.value}
                      />
                    ) : type === 'file' ? (
                      <FileUpload
                        onChange={({ file, fileName }) => {
                          if (onChange) {
                            onChange(file) // If you still want to pass just the file
                          }
                          form.setFieldValue(name, file) // Store the file in the form state
                          form.setFieldValue(`${name}FileName`, fileName) // Store the file name separately if needed
                        }}
                      />
                    ) : type === 'fileUploadForMobile' ? (
                      <FileUploadForMobile
                        file={file}
                        name={name}
                        form={form}
                        value={field?.value}
                        onChange={(event) => {
                          onChange && onChange(event)
                        }}
                      />
                    ) : type === 'textarea' ? (
                      <Textarea
                        value={field?.value}
                        onChange={(event: any) => {
                          form.setFieldValue(name, event.target.value)
                          onChange && onChange(event.target.value)
                        }}
                        placeholder={placeholder}
                        border={border}
                        disabled={disabled}
                        height={height}
                      />
                    ) : type === 'choose' && Array.isArray(data) ? (
                      <ChooseButton
                        select={select}
                        data={data}
                        name={name}
                        value={field?.value}
                        buttonStyle={buttonStyle}
                        form={form}
                        onChange={(value: any) => {
                          onChange && onChange(value)
                        }}
                        disabled={disabled}
                      />
                    ) : type === 'switch' ? (
                      <Switchs name={name} />
                    ) : type === 'radio' ? (
                      <RadioButton
                        labelArray={radioProps?.labelArray ?? []}
                        name={name}
                        size={radioProps?.size ?? 'lg'}
                        onChange={(value) => {
                          console.log('Selected value:', value)
                        }}
                      />
                    ) : type === 'range' ? (
                      <RangeInputField
                        minValue={rangeProps?.minValue || 0}
                        maxValue={rangeProps?.maxValue || 100}
                        name={name}
                        form={form}
                        multiple={rangeProps?.multiple}
                      />
                    ) : type === 'checkbox' ? (
                      <Checkbox
                        checkboxElement={
                          checkboxProps ? checkboxProps.checkboxElement : []
                        }
                        name={name}
                        form={form}
                        mainStyle={style}
                        style={checkboxProps?.style}
                      />
                    ) : type === 'dropdown' && dropDownProps !== undefined ? (
                      <DropDown
                        childrenComponent={dropDownProps.childrenComponent}
                        expanded={dropDownProps.expanded}
                        headerComponent={dropDownProps.headerComponent}
                        setExpanded={dropDownProps.setExpanded}
                        headerStyle={dropDownProps.headerStyle}
                        icon={dropDownProps.icon}
                      />
                    ) : type === 'searchDropdown' &&
                      searchDropDownProps !== undefined ? (
                      <SearchDropdown
                        name={name}
                        childrenComponent={
                          searchDropDownProps.childrenComponent
                        }
                        expanded={searchDropDownProps.expanded}
                        setExpanded={searchDropDownProps.setExpanded}
                        onChange={onChange || (() => {})}
                        value={field.value}
                        placeholder={placeholder}
                      />
                    ) : type === 'styledInput' ? (
                      <StyledInput />
                    ) : (
                      <>
                        <IconStyle>
                          {icon}
                          <IcontextStyle>{iconText}</IcontextStyle>
                        </IconStyle>

                        <Input
                          name={name}
                          id={id ?? ''}
                          placeholder={placeholder || ''}
                          type={type}
                          disabled={disabled}
                          onChange={(event) => {
                            !!onChange && onChange(event.target.value)
                            form.setFieldValue(name, event.target.value)
                          }}
                          value={field.value}
                          style={style}
                          {...restProps}
                        />
                      </>
                    )}
                    {styledLabelRef && styledLabel && (
                      <LabelStyle htmlFor={type} disabled={disabled ? 1 : 0}>
                        {label}
                      </LabelStyle>
                    )}
                  </StyledFormInput>
                  {meta.touched && meta.error ? (
                    <Typography variant="p" fontSize="sm" fontColor="primary">
                      {meta.error}
                    </Typography>
                  ) : null}
                </Column>
              </StyleInputWrapper>
            )
          }}
        </Field>
      </Column>
    </>
  )
}
forwardRef(FormField)
export default FormField

const LabelStyle = styled.label<{ disabled?: 0 | 1 }>`
  ${(props) =>
    props.disabled &&
    `    
      background: ${theme.colors.white500} !important;
      z-index: 1;
    `}
`

const StyledFormInput = styled.div<{
  stylelabel: 0 | 1
  type: IFormType
  hasvalue: 0 | 1
}>`
  display: ${(props) =>
    props.type.type === 'file' ||
    props.type.type === 'radio' ||
    props.type.type === 'checkbox' ||
    props.type.type === 'range' ||
    props.type.type === 'dropdown' ||
    props.type.type === 'searchDropdown' ||
    props.type.type === 'password' ||
    props.type.type === 'phoneNumber'
      ? 'block'
      : 'flex'};
  position: relative;
  width: 100%;

  /* div:focus-within {
    border-color: ${(props) =>
    props.type.type !== 'radio' ? theme.colors.gray50 : theme.colors.gray50};
  } */

  input::placeholder {
    opacity: ${(props) => (props.stylelabel ? '0' : '1')};
    font-size: 12px;
  }

  input,
  textarea {
    width: ${(props) => (props.type.type === 'radio' ? 'auto' : '100%')};
    font-size: 14px;
    outline: none;
    border-radius: 4px;
    z-index: 1;
    height: 41px;
    position: ${(props) => props.type.type !== 'checkbox' && 'auto'};
    background: transparent;
    &:focus {
      border: ${(props) =>
        props.type.type === 'phoneNumber' ||
        props.type.type === 'range' ||
        props.type.type === 'dropdown' ||
        props.type.type === 'searchDropdown'
          ? 'none'
          : `1px solid ${theme.colors.grey50}`};
    }
  }

  input:disabled,
  input:disabled ~ label,
  textarea:disabled ~ label,
  div:disabled ~ label,
  div:disabled ~ label {
    background: ${theme.colors.white500} !important;
    z-index: 1;
  }

  input:focus::placeholder,
  textarea:focus::placeholder,
  div:focus-within::placeholder,
  div:focus-within::placeholder {
    opacity: 1;
    font-size: 14px;
    /* border-color: ${theme.colors.primary}; */
  }

  input:focus ~ label,
  textarea:focus ~ label,
  div:focus-within ~ label,
  div:focus-within ~ label {
    top: 0;
    font-size: 12px;
    left: ${(props) => (props.type.type === 'phoneNumber' ? '20px' : '10px')};
    z-index: 1;
    background-color: ${theme.colors.white};
    color: ${theme.colors.grey500};
    ${(props) => props.type.type !== 'radio' && 'padding: 0 5px;'}
  }

  ${(props) =>
    props.type.type !== 'switch' &&
    `
    label {
      width: ${props.type.type === 'radio' ? '100%' : props.type.type !== 'file' && 'auto'};
      position: ${props.type.type === 'file' || props.type.type === 'radio' ? 'static' : 'absolute'};
      left: ${props.type.type === 'phoneNumber' ? '60px' : '15px'};
      top: ${props.type.type === 'file' || props.type.type === 'radio' ? '0' : '50%'};
      transform: ${props.type.type === 'file' || props.type.type === 'radio' ? '0' : 'translateY(-50%)'};
      font-size: 14px;
      color: gray;
      transition: 0.3s;
      z-index:2 !important;
 
      ${
        props.hasvalue &&
        `
        top: 0;
        font-size: 12px;
        left: ${props.type.type === 'phoneNumber' ? '20px' : '10px'};
        background: #fff;
        padding: ${props.type.type !== 'file' && '0 5px'};
        background-color: white;
        z-index: 2 !important;
      `
      }
    }
  `}
`

const IcontextStyle = styled.div`
  font-weight: 400;
  font-size: ${theme.fontSizes['lg']}px;
  color: ${theme.colors.gray500};
`

const IconStyle = styled.div`
  display: flex;
  align-items: center;
`
